import React, { useState, useEffect } from 'react';
import { Container, colors } from '@mui/material';
import { useQuery, useMutation } from '@apollo/client';

import Form from '../../common/form/Form';
import { useToast } from '../../common/hooks/useToast';
import { CATEGORIES } from '../../graphql/queries';
import { CREATE_ACHIEVEMENT } from '../../graphql/mutation';
import { openAppFormOptions, recommendBusinessOptions, formOptions } from './achievement';

interface PropsInterface {
    handleClose: (isSuccess?: number) => void;
}

const defaultValue = {
    title: '',
    description: '',
    points: '',
    achievementType: '',
    numTimesToOpen: '',
    category: '',
    withinHours: '',
    everyHours: '',
    numBusinessesToRecommend: '',
};

export const AddAchievement: React.FC<PropsInterface> = (props: PropsInterface) => {
    const { error, success } = useToast();

    const { data, loading } = useQuery(CATEGORIES);
    const [addAchievement, result] = useMutation(CREATE_ACHIEVEMENT);

    const [values, setValues] = useState(defaultValue);
    const [page, setPage] = useState(1);

    useEffect(() => {
        if (result.error) {
            error('Creating an achievement failed. Please try again.');
        } else if (result.data) {
            success('Achievement created successfully');
            props.handleClose(1);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [result.error, result.data]);

    const handleSubmit = async (currentValues: any) => {
        const { points, title } = values;
        const body: any = {
            title: title,
            points: Number(points)
        };

        if (values.achievementType === 'OpenAppAchievement') {
            body['OpenAppAchievement'] = {
                create: {
                    everyHours: Number(currentValues.everyHours),
                    withinHours: Number(currentValues.withinHours),
                    numTimesToOpen: Number(currentValues.numTimesToOpen),
                }
            }
        } else if (values.achievementType === 'RecommendBusinessAchievement') {
            body['RecommendBusinessAchievement'] = {
                create: {
                    numBusinessesToRecommend: Number(currentValues.numBusinessesToRecommend),
                    RecommendBusinessAchievementCategories: {
                        create: {
                            categoryId: Number(currentValues.category)
                        }
                    }
                }
            }
        }
        addAchievement({ variables: { input: body } });
    };

    const handleFirstPageSubmit = (formValues: any) => {
        setValues((values) => ({
            ...values,
            ...formValues,
        }));
        setPage(2);
    }

    if (loading) return <h4>Loading...</h4>;

    const categories = data.fetchCategories.categories.map((item: { category: string; id: string; }) => ({ label: item.category, value: item.id }));

    return (
        <Container maxWidth='lg' sx={{ width: '45rem', backgroundColor: colors.common.white, p: '2rem', border: '1px solid black' }}>
            {page === 1 ?
                <Form
                    title="Add Achievement : Part 1"
                    defaultValue={defaultValue}
                    formFields={formOptions}
                    handleSubmit={handleFirstPageSubmit}
                    handleCancel={() => props.handleClose()}
                    submitBtnTitle="Next"
                /> :
                <Form
                    title="Add Achievement : Part 2"
                    defaultValue={defaultValue}
                    formFields={values.achievementType === 'OpenAppAchievement' ? openAppFormOptions : recommendBusinessOptions(categories)}
                    handleSubmit={handleSubmit}
                    handleCancel={() => props.handleClose()}
                />
            }
        </Container>
    );
};
