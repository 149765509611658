import { gql } from "@apollo/client";

export const USERS = gql`
  query fetchUsers(
    $where: UserWhereInput
    $pagination: PaginationInputType
    $orderBy: UserOrderByWithRelationInput
  ) {
    fetchUsers(where: $where, pagination: $pagination, orderBy: $orderBy) {
      users {
        id
        username
        firstName
        lastName
        email
        phone
        totalPoints
        isNotificationEnabled
        membershipType
        isEmailVerified
        isPhoneVerified
        City {
          name
        }
        Country {
          name
        }
        State {
          name
        }
        ReferredByReferral {
          RefferedByUser {
            firstName
            lastName
            username
          }
        }
        Referrals {
          id
        }
      }
      total
    }
  }
`;
export const ACHIEVEMENTS = gql`
  query fetchAchievements(
    $pagination: PaginationInputType
    $orderBy: AchievementOrderByWithRelationInput
  ) {
    fetchAchievements(pagination: $pagination, orderBy: $orderBy) {
      achievements {
        id
        type
        title
        points
        description
      }
      total
    }
  }
`;
export const CATEGORIES = gql`
  query fetchCategories {
    fetchCategories {
      categories {
        id
        category
      }
    }
  }
`;
export const BUSINESSES = gql`
  query getAllBusinesses(
    $query: BusinessInputType
    $where: BusinessWhereInput
    $pagination: PaginationInputType
  ) {
    getAllBusinesses(query: $query, where: $where, pagination: $pagination) {
      businesses {
        id
        name
        email
        url
        phone
        BusinessRecommendations {
          id
          isBlackFriendly
        }
        BusinessAddresses {
          id
          formattedAddress
          lat
          lng
        }
      }
      total
    }
  }
`;
export const FETCH_USERS_WITH_TOKEN = gql`
  query fetchUsersWithTokens(
    $pagination: PaginationInputType
    $where: UserWhereInput
  ) {
    fetchUsersWithTokens(where: $where, pagination: $pagination) {
      users {
        id
        UserToken {
          fcmToken
        }
        firstName
        lastName
      }
      total
    }
  }
`;
