import { gql } from '@apollo/client';

export const ADMIN_LOGIN = gql`
    mutation adminLogin($input: AdminLoginInput!) {
        adminLogin(input: $input) {
        email
        accessToken
        }
    }
`
export const DELETE_ACHIEVEMENT = gql`
    mutation removeAchievement($id: ID!) {
        removeAchievement(id: $id) {
            id
            points
            title
            description
        }
    }
`
export const CREATE_ACHIEVEMENT = gql`
    mutation createAchievement($input: AchievementCreateInput!) {
        createAchievement(input: $input) {
            id
            points
            title
            description
        }
    }
`
export const UPDATE_ACHIEVEMENT = gql`
    mutation updateAchievement($id: Int!, $input: AchievementUpdateInput!) {
        updateAchievement(id: $id, input: $input) {
            id
            points
            title
            description
        }
    }
`
export const DELETE_BUSINESS = gql`
    mutation removeBusiness($id: Int!) {
        removeBusiness(id: $id) {
            id
            name
            email
        }
    }
`
export const UPDATE_BUSINESS = gql`
    mutation updateBusiness($id: Int!, $input: BusinessUpdateInput!) {
        updateBusiness(id: $id, updateBusinessInput: $input) {
            id
            name
            email
        }
    }
`

export const CREATE_BUSINESS = gql`
    mutation createBusiness($input: BusinessCreateInput!, $files: [Upload!]) {
        createBusiness (input: $input, files: $files) {
            id
            name
            email
            url
            phone
            categoryId
        }
    }
`
export const CREATE_MANY_BUSINESS = gql`
    mutation createManyBusiness($input: [BusinessCreateManyInput!]!) {
        createManyBusiness(input: $input) {
            count
        }
    }
`
export const CREATE_NOTIFICATION = gql`
    mutation createNotification($input: NotificationInput!) {
        createNotification(input: $input) {
            id
            status
            createdAt
        }
    }
`