import React, { useEffect } from 'react';
import { Container, colors } from '@mui/material';
import { useMutation } from '@apollo/client';

import Form from '../../common/form/Form';
import { basicOptions } from './achievement';
import { useToast } from '../../common/hooks/useToast';
import { UPDATE_ACHIEVEMENT } from '../../graphql/mutation';

interface PropsInterface {
    achievement: any;
    handleClose: (isSuccess?: number) => void;
}
export const EditAchievement: React.FC<PropsInterface> = (props: PropsInterface) => {
    const { error, success } = useToast();
    const [editAchievement, result] = useMutation(UPDATE_ACHIEVEMENT);

    useEffect(() => {
        if (result.error) {
            error('Updating an achievement failed. Please try again.');
        } else if (result.data) {
            success('Achievement updated successfully');
            props.handleClose(1);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [result.error, result.data]);

    const handleSubmit = async (values: any) => {
        const id = Number(values.id);
        values.points = Number(values.points);

        delete values.id;
        delete values.__typename;

        const body = Object.keys(values).reduce((acc: any, item: string | number) => {
            acc[item] = { "set": values[item] }

            return acc;
        }, {});

        editAchievement({ variables: { id, input: body } })
    }
    
    return (
        <Container maxWidth='lg' sx={{ width: '45rem', backgroundColor: colors.common.white, p: '2rem', border: '1px solid black' }}>
            <Form
                title="Edit Achievement"
                defaultValue={props.achievement}
                formFields={basicOptions}
                handleSubmit={handleSubmit}
                handleCancel={() => props.handleClose()}
                submitBtnTitle="Update"
            />
        </Container>
    );
}