import React, { useCallback, useEffect, useState } from "react";
import { Button, Stack, TextField } from "@mui/material";
import { useMutation } from "@apollo/client";
import { useNavigate } from "react-router-dom";

import { useToast } from "../../common/hooks/useToast";
import { ADMIN_LOGIN } from "../../graphql/mutation";
import { saveTokens } from "../../common/manage_tokens";
import { useSignInWithEmailAndPassword } from "react-firebase-hooks/auth";
import { getAuth } from "firebase/auth";

interface PropsInterface {
  loginMethod: string;
}

export const LoginDetails: React.FC<PropsInterface> = ({
  loginMethod,
}: PropsInterface) => {
  const navigate = useNavigate();
  const auth = getAuth();
  const { error: toastError, success } = useToast();

  const [signInWithEmailAndPassword, user, loading, error] =
    useSignInWithEmailAndPassword(auth);

  const [values, setValues] = useState({
    id: "",
    password: "",
  });

  useEffect(() => {
    if (error) {
      toastError("Login failed. Please try again");
    } else if (user) {
      console.log(user);
      user.user.getIdToken().then((token) => {
        saveTokens(token);
        success("Login Successful!");
        navigate("/users");
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error, user, success, toastError]);

  const handleChange = useCallback((event: any) => {
    setValues((prevState) => ({
      ...prevState,
      [event.target.name]: event.target.value,
    }));
  }, []);

  const handleSubmit = (event: any) => {
    event.preventDefault();
    try {
      signInWithEmailAndPassword(values.id, values.password);
    } catch (err) {
      // console.log('err: ', err);
    }
  };

  return (
    <form autoComplete="off" noValidate onSubmit={handleSubmit}>
      <Stack spacing={3}>
        <TextField
          fullWidth
          label={loginMethod === "email" ? "Email Address" : "Phone Number"}
          name="id"
          onChange={handleChange}
          required
          value={values.id}
        />

        <TextField
          fullWidth
          type="password"
          label="Password"
          name="password"
          onChange={handleChange}
          required
          value={values.password}
        />

        <Button
          disabled={loading}
          fullWidth
          size="large"
          sx={{ mt: 3 }}
          type="submit"
          variant="contained"
        >
          Login
        </Button>
      </Stack>
    </form>
  );
};
