import React from 'react';
import { BsUpload } from 'react-icons/bs';
import { Button, Box, FormHelperText } from '@mui/material';

interface PropsInterface {
    field: any;
    error: string | null;
    value: any;
    accept?: string;
    handleChange: (event: any) => void;
}

const FileField: React.FC<PropsInterface> = ({ field, error, value, accept, handleChange }: PropsInterface) => {

    return (
        <Button
            component="label"
            variant="outlined"
            startIcon={<BsUpload />}
            sx={{ marginRight: "1rem" }}
        >
            <Box>{value?.name ? value.name : field.label}</Box>
            <input type="file" accept={accept ?? '*'} hidden onChange={handleChange} />
            <FormHelperText error>{error ?? ''}</FormHelperText>
        </Button>
    )
}

export default FileField;
