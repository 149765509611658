import React from 'react';
import { useNavigate } from 'react-router-dom';
import { FaUserCircle } from 'react-icons/fa';
import { styled, colors, Menu, MenuItem } from '@mui/material';
import { deleteTokens } from '../manage_tokens';

const HeaderLayout = styled('div')(({ headerHeight }: { headerHeight: string }) => ({
    width: '100%',
    height: headerHeight,
    color: colors.grey[100],
    backgroundColor: colors.grey[900],
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    fontSize: '1.25rem',
    paddingLeft: '3rem'
}));

interface PropsInterface {
    toggle: boolean;
    headerHeight: string;
    setToggle: (toggle: boolean) => void;
}

export const Header = ({ toggle, setToggle, headerHeight }: PropsInterface) => {
    const navigate = useNavigate();

    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  
    const handleMenu = (event: any) => {
      setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const logout = () => {
        setAnchorEl(null);
        deleteTokens();
        navigate('/account/login');
    }

    return (
        <HeaderLayout headerHeight={headerHeight}>
            <h3>Admin App</h3>

            <FaUserCircle
                onClick={handleMenu}
                style ={{
                    marginRight: '3rem',
                    fontSize: '27px',
                    cursor: 'pointer',
                }}
            />

            <Menu
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleClose}
              >
                <MenuItem onClick={logout}>Logout</MenuItem>
            </Menu>
        </HeaderLayout>
    )
}