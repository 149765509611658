import React, { useState, useEffect } from 'react';
import { useQuery, useMutation } from '@apollo/client';
import { VscAdd } from 'react-icons/vsc';
import { AiOutlineExclamationCircle } from 'react-icons/ai';
import { Box, Grid, Typography, FormControl, InputLabel, Select, MenuItem, Card, CardHeader, CardContent, CardActions, Button } from '@mui/material';

import { Item } from '../../styles/common';
import { Table, columnTypes } from "../../common/table/Table";
import { HeaderLayout, columns, sortByOptions } from './achievement';
import { ACHIEVEMENTS } from '../../graphql/queries';
import { DELETE_ACHIEVEMENT } from '../../graphql/mutation';
import { useToast } from '../../common/hooks/useToast';
import { Popover } from '../../common/popover/Popover';
import { AddAchievement } from './AddAchievement';
import { EditAchievement } from './EditAchievement';
import { ErrorNotifier } from '../../common/error/ErrorNotifier';

const perPage = Number(process.env.REACT_APP_PAGINATION_ROWS_PER_PAGE ?? 5);

export const Achievements = () => {
    let variables = React.useRef<any>({
        "pagination": {
            "skip": 0,
            "take": perPage
        }
    });
    const { error: toastError, success } = useToast();
    const { data, loading, error, refetch } = useQuery(ACHIEVEMENTS, { variables });
    const [deleteAchievement, result] = useMutation(DELETE_ACHIEVEMENT);

    const [id, setId] = useState<number | null>();
    const [open, setOpen] = useState(false);
    const [page, setPage] = useState(0);
    const [sortBy, setSortBy] = useState<string>();
    const [rowsPerPage, setRowsPerPage] = useState(perPage);
    const [achievement, setAchievement] = useState(null);
    const [openAddAchievement, setOpenAddAchievement] = useState(false);
    const [openEditAchievement, setOpenEditAchievement] = useState(false);

    useEffect(() => {
        variables.current = {
            pagination: {
                take: rowsPerPage,
                skip: page * rowsPerPage,
            }
        };

        if (sortBy) {
            const [key, sortOrder] = sortBy.split(' ');
            variables.current = {
                ...variables.current,
                orderBy: {[key]: sortOrder},
            }
        }

        refetch(variables.current);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [page, rowsPerPage, sortBy]);

    useEffect(() => {
        if (result.error) toastError('Failed to delete Achievement. Please try again.');
        else if (result.data) {
            success('Achievement deleted successfully.');
            setOpen(false);
            setId(null);
            refetch(variables.current);
        }

    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [result.data, result.error]);

    const onDelete = (id: number) => {
        setId(id);
        setOpen(true);
    };

    const editFn = (item: any) => {
        setAchievement(item);
        setOpenEditAchievement(true);
    };

    if (loading) return <h4>Loading...</h4>;

    return (
        <Box sx={{ flexGrow: 1 }}>
            <ErrorNotifier error={error} />
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Item>
                        <Grid
                            container
                            direction="row"
                            justifyContent="space-between"
                            alignItems="center"
                        >
                            <Typography sx={{ m: 2 }} variant="h4">Achievements</Typography>

                            <HeaderLayout>
                                <VscAdd
                                    size={40}
                                    style={{ cursor: 'pointer' }}
                                    title='Create an Achievement'
                                    onClick={() => setOpenAddAchievement(true)}
                                />

                                <FormControl sx={{ m: 1, minWidth: 120 }}>
                                    <InputLabel id="demo-simple-select-helper-label">Sort By</InputLabel>
                                    <Select
                                        value={sortBy}
                                        label="Sort By"
                                        onChange={((e: any) => setSortBy(e.target.value))}
                                    >
                                        {sortByOptions.map((option) => (
                                            <MenuItem key={option.value} value={option.value}>{option.label}</MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </HeaderLayout>
                        </Grid>
                    </Item>
                </Grid>
                <Grid item xs={12}>
                    <Item>
                        <Table
                            page={page}
                            setPage={setPage}
                            rowsPerPage={rowsPerPage}
                            setRowsPerPage={setRowsPerPage}
                            loading={loading || result.loading}
                            columns={
                                [
                                    ...columns,
                                    { label: '', key: 'action', textAlign: 'right', type: columnTypes.EDIT, actionFn: editFn }
                                ]
                            }
                            onDelete={onDelete}
                            total={data ? data.fetchAchievements.total : 0}
                            rows={data ? data.fetchAchievements.achievements : []}
                        />

                        <Popover
                            open={openAddAchievement}
                            handleClose={() => {
                                setOpenAddAchievement(false)
                        }}>
                            <AddAchievement handleClose={(isSuccess: number | undefined) => {
                                if (isSuccess) { refetch(variables.current); }
                                setOpenAddAchievement(false)
                            }} />
                        </Popover>

                        <Popover
                            open={openEditAchievement}
                            handleClose={() => {
                                setOpenEditAchievement(false)
                        }}>
                            <EditAchievement achievement={achievement} handleClose={(isSuccess: number | undefined) => {
                                if (isSuccess) { refetch(variables.current); }
                                setOpenEditAchievement(false)
                            }} />
                        </Popover>

                        <Popover open={open} handleClose={() => setOpen(false)}>
                            <Card sx={{ border: '1px solid black' }}>
                                <div style={{
                                    display: 'flex',
                                    height: '60px',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                }}>
                                    <AiOutlineExclamationCircle size={45} color="orange" />
                                </div>
                                <CardHeader title="Delete Achievement" />
                                <CardContent>
                                    <p>Once deleted, the achievement cannot be recovered. Are you sure you want to delete.</p>
                                </CardContent>
                                <CardActions>
                                    <Button variant="contained" color="success" onClick={() => setOpen(false)}>Cancel</Button>
                                    <Button
                                        variant="contained"
                                        color="error"
                                        onClick={() => deleteAchievement({ variables: { id: id } })}>
                                            Yes
                                    </Button>
                                </CardActions>
                            </Card>
                        </Popover>
                    </Item>
                </Grid>
            </Grid>
        </Box>
    )
}