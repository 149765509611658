import React from 'react';
import TextField from './TextField';
import NumberField from './NumberField';
import SelectField from './SelectField';
import ArrayField from './ArrayField';
import FileField from './File';

interface PropsInterface {
    field: any;
    error: any;
    values: any;
    setValues: (value: any) => void;
    customMenu?: (options: Array<any>) => Array<React.ReactNode>;
}
const Field: React.FC<PropsInterface> = (props: PropsInterface) => {

    const handleChange = (event: any) => {
        event.stopPropagation();
        const { name, value } = event.target;

        props.setValues((values: any) => ({
            ...values,
            [name]: value,
        }));
    }
    
    const getField = () => {
        switch (props.field.type) {
            case 'number':  return <NumberField
                field={props.field}
                error={props.error[props.field.key] ?? ''}
                value={props.values[props.field.key]}
                handleChange={handleChange}
            />

            case 'select':  return <SelectField
                field={props.field}
                error={props.error[props.field.key] ?? ''}
                value={props.values[props.field.key] ? props.values[props.field.key] : props.field.multiple ? [] : null}
                handleChange={handleChange}
            />

            case 'file': return <FileField field={props.field} error={props.error[props.field.key] ?? ''} value={props.values[props.field.key]} handleChange={handleChange} />

            case 'Array:file': return <ArrayField {...props} />

            default: return <TextField field={props.field} error={props.error[props.field.key] ?? ''} value={props.values[props.field.key]} handleChange={handleChange} />
        }

    }

    return (getField())
}

export default Field;
