class FormService {

    validateForm(values: any, options: Array<any>) {
        const errors: any = {};

        options.forEach((option) => {
            const result = this.validateField(option, values.hasOwnProperty(option.key) ? values[option.key] : null);
            
            if (result) errors[option.key] = result;
        });

        return errors;
    }

    validateField(field: any, value: any) {
        const { rules, errorMessages } = field;

        if (!rules) return;

        if (rules.hasOwnProperty('required') && !value) {
            return errorMessages['required'] ?? 'Field is required.';
        }
        if (value && rules.hasOwnProperty('minLength') && value.length < rules.minLength) {
            return errorMessages.minLength ?? `Length of field should be more than or equal to ${rules.minLength}`;
        }
        if (rules.hasOwnProperty('min') && value < rules.min) {
            return errorMessages.min ?? `Field should be more than or equal to ${rules.min}`;
        }
    }

    async readCSVFile(e: React.ChangeEvent<HTMLInputElement>) {
        const file = e.target.files && e.target.files[0];
        if (!file) return;

        const fileUrl = URL.createObjectURL(file);

        const response = await fetch(fileUrl);

        const text = await response.text();

        const lines = text.split('\n');

        return lines;
    }
}

// eslint-disable-next-line import/no-anonymous-default-export
export default new FormService();
