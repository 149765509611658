import React, { useEffect } from 'react';
import { Container, colors } from '@mui/material';
import { useMutation } from '@apollo/client';

import Form from '../../common/form/Form';
import { editBusinessOptions } from './business';
import { useToast } from '../../common/hooks/useToast';
import { UPDATE_BUSINESS } from '../../graphql/mutation';

interface PropsInterface {
    business: any;
    handleClose: (isSuccess?: number) => void;
}
export const EditBusiness: React.FC<PropsInterface> = (props: PropsInterface) => {
    const { error, success } = useToast();
    const [editBusiness, result] = useMutation(UPDATE_BUSINESS);

    useEffect(() => {
        if (result.error) {
            error('Updating a Business failed. Please try again.');
        } else if (result.data) {
            success('Business updated successfully');
            props.handleClose(1);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [result.error, result.data]);

    const handleSubmit = async (values: any) => {
        const id = Number(values.id);

        delete values.id;
        delete values.noOfRecommendations;
        delete values.BusinessRecommendations;
        delete values.__typename;

        const body = Object.keys(values).reduce((acc: any, item: string | number) => {
            acc[item] = { "set": values[item] }

            return acc;
        }, {});

        editBusiness({ variables: { id, input: body } })
    }
    
    return (
        <Container maxWidth='lg' sx={{ width: '45rem', backgroundColor: colors.common.white, p: '2rem', border: '1px solid black' }}>
            <Form
                title="Edit Business"
                defaultValue={props.business}
                formFields={editBusinessOptions}
                handleSubmit={handleSubmit}
                handleCancel={() => props.handleClose()}
                submitBtnTitle="Update"
            />
        </Container>
    );
}
