import React, { useState } from 'react';
import { Grid, Typography, Button, styled, Stack } from '@mui/material';

import Field from './Field';
import formService from '../../common/services/form-service';

const ButtonContainer = styled('div')`
    display: flex;
    margin-top: 3em;
`

interface PropsInterface {
    title: string;
    defaultValue: any;
    formFields: Array<any>;
    handleSubmit: (values: any) => void;
    handleCancel?: () => void;
    submitBtnTitle?: string;
    cancelBtnTitle?: string;
}

const Form: React.FC<PropsInterface> = (props: PropsInterface) => {
    const [values, setValues] = useState(props.defaultValue);
    const [error, setError] = useState({});

    const handleSubmit = async (event: any) => {
        event.preventDefault();

        const response = formService.validateForm(values, props.formFields);
        setError(response);

        if (!Object.keys(response).length) {
            props.handleSubmit(values);
        }
    }

    return (
        <Grid item xs={12}>
            <Typography sx={{ mb: 3 }} variant="h4">{props.title}</Typography>
            
            <form
                autoComplete="off"
                noValidate
                onSubmit={handleSubmit}
            >
                <Stack spacing={2}>
                {
                    props.formFields.map((field) => (
                        <Field key={field.key} field={field} error={error} values={values} setValues={setValues} />
                    ))
                }
                </Stack>

                <ButtonContainer>
                    <Button size="large" sx={{ marginRight: 3 }} type="submit" variant="contained" color="success">
                        {props.submitBtnTitle ?? 'Submit'}
                    </Button>
                    {props.handleCancel ?
                        <Button size="large" type="button" color="error" onClick={() => props.handleCancel ? props.handleCancel() : null}>
                            {props.cancelBtnTitle ?? 'Cancel'}
                        </Button> : null}
                </ButtonContainer>
            </form>
        </Grid>
    )
}

export default Form;
