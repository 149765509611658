import { styled } from '@mui/material';
import { ColumnInterface, columnTypes } from "../../common/table/Table";

export const HeaderLayout = styled('div')`
    display: flex;
    align-items: center;
`;

export const columns: Array<ColumnInterface> = [
    { label: 'Title', key: 'title' },
    { label: 'Achievement Type', key: 'type' },
    { label: 'Points', key: 'points', textAlign: 'right' },
    { label: 'Description', key: 'description', textAlign: 'right' },
    { label: '', key: 'action', textAlign: 'right', type: columnTypes.DELETE },
];

export const sortByOptions = [
    { label: 'Points - Ascending', value: 'points asc' },
    { label: 'Points - Descending', value: 'points desc' },
];

export const AchievementOptions = [
    { label: 'Open App Achievement', value: 'OpenAppAchievement' },
    { label: 'Recommend Business Achievement', value: 'RecommendBusinessAchievement' },
];

export const basicOptions = [
    {
        key: 'title',
        label: 'Title',
        rules: {
            required: null,
            minLength: 2,
        },
        errorMessages: {
            required: 'Title is required',
            minLength: 'Title length should be more than or equal to 2.',
        }
    },
    {
        key: 'description',
        label: 'Description',
        rules: {
            minLength: 2,
        },
        errorMessages: {
            minLength: 'Title length should be more than or equal to 2.',
        }
    },
    {
        type: 'number',
        key: 'points',
        label: 'Points',
        rules: {
            required: null,
            min: 2,
        },
        errorMessages: {
            required: 'Points is required',
            min: 'Points should be more than or equal to 2.',
        }
    },
];
export const formOptions = [
    ...basicOptions,
    {
        type: 'select',
        key: 'achievementType',
        label: 'Achievement Type',
        options: AchievementOptions,
        rules: {
            required: null,
        },
        errorMessages: {
            required: 'Achievement Type is required',
        }
    },
];
export const openAppFormOptions = [
    {
        type: 'number',
        key: 'numTimesToOpen',
        label: 'Number of times to open',
        rules: {
            required: null,
            min: 2,
        },
        errorMessages: {
            required: 'Number of times to open is required',
            min: 'Number of times to open should be more than or equal to 2.',
        }
    },
    {
        type: 'number',
        key: 'withinHours',
        label: 'Within hours',
        rules: {
            required: null,
        },
        errorMessages: {
            required: 'Within Hours is required',
        }
    },
    {
        type: 'number',
        key: 'everyHours',
        label: 'After every hour',
        rules: {
            required: null,
        },
        errorMessages: {
            required: 'After every hour is required',
        }
    },
];
export const recommendBusinessOptions = (categories: Array<{ label: string, value: string }>) => [
    {
        type: 'number',
        key: 'numBusinessesToRecommend',
        label: 'Number of businesses to recommend',
        rules: {
            required: null,
            min: 2,
        },
        errorMessages: {
            required: 'Number of businesses to recommend is required',
            min: 'Number of businesses to recommend should be more than or equal to 2.',
        }
    },
    {
        type: 'select',
        key: 'category',
        label: 'Category',
        options: categories,
        rules: {
            required: null,
        },
        errorMessages: {
            required: 'Category is required',
        }
    },
];