import React from 'react';
import { Container, colors, Typography } from '@mui/material';

export const PageNotFound: React.FC<any> = () => {

    return (
        <Container
            maxWidth='lg'
            sx={{
                background: colors.common.white,
            }}
        >
            <Typography sx={{ m: 3 }} variant="h4">Page Not Found</Typography>
        </Container>
    )
}