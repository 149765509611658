import React from "react";
import { TextField as Field } from "@mui/material";

interface PropsInterface {
  field: any;
  error: string | null;
  value: number;
  handleChange: (event: any) => void;
}

const TextField: React.FC<PropsInterface> = ({
  field,
  error,
  value,
  handleChange,
}: PropsInterface) => {
  return (
    <Field
      fullWidth
      label={field.label}
      name={field.key}
      onChange={handleChange}
      value={value}
      error={!!error}
      helperText={error ?? ""}
    />
  );
};

export default TextField;
