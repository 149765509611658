import React from "react";

import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TablePagination,
  colors,
  Divider,
} from "@mui/material";
import { BsTrash3Fill, BsFillPencilFill } from "react-icons/bs";
var _ = require("lodash");
export enum columnTypes {
  EDIT = "EDIT",
  DELETE = "DELETE",
}
export interface ColumnInterface {
  label: string;
  key: string;
  type?: columnTypes;
  minWidth?: string;
  rowRender?: (row: any) => string;
  actionFn?: (info: any) => void;
  textAlign?: "right" | "left" | "center" | "inherit" | "justify" | undefined;
}
interface PropsInterface {
  loading: boolean;
  rows: Array<any>;
  columns: Array<ColumnInterface>;
  page: number;
  setPage: (page: number) => void;
  rowsPerPage: number;
  setRowsPerPage: (rowsPerPage: number) => void;
  total: number;
  onDelete?: (id: number) => void;
}

const CustomTable = (props: PropsInterface) => {
  const handleChangePage = (event: unknown, newPage: number) => {
    props.setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    props.setRowsPerPage(parseInt(event.target.value, 10));
    props.setPage(0);
  };

  const showColumns = () => {
    return props.columns.map((column: ColumnInterface, index: number) => (
      <TableCell key={index} align={column.textAlign ?? undefined}>
        {column.label}
      </TableCell>
    ));
  };

  const showRow = (row: any) => {
    return props.columns.map((column: ColumnInterface, index: number) => {
      if (column.type === columnTypes.DELETE) {
        return (
          <TableCell
            key={index}
            align={column.textAlign ?? undefined}
            sx={{ width: "75px" }}
          >
            <BsTrash3Fill
              color="red"
              size={20}
              style={{ cursor: "pointer" }}
              onClick={() => {
                if (column.actionFn) {
                  column.actionFn(row);
                } else if (props.onDelete) {
                  props.onDelete(row.id);
                }
              }}
            />
          </TableCell>
        );
      } else if (column.type === columnTypes.EDIT) {
        return (
          <TableCell
            key={index}
            align={column.textAlign ?? undefined}
            sx={{ width: "75px" }}
          >
            <BsFillPencilFill
              color={colors.grey[500]}
              size={20}
              style={{ cursor: "pointer" }}
              onClick={() => {
                if (column.actionFn) {
                  column.actionFn(row);
                } else if (props.onDelete) {
                  props.onDelete(row.id);
                }
              }}
            />
          </TableCell>
        );
      }

      return (
        <TableCell
          key={index}
          align={column.textAlign ?? undefined}
          sx={{ minWidth: column.minWidth ?? "200px" }}
        >
          {(column.rowRender && column.rowRender(row)) ??
            _.get(row, column.key)}
        </TableCell>
      );
    });
  };

  const showRows = () => {
    if (!props.rows.length) {
      return (
        <TableRow sx={{ display: "block", pt: 3, height: 60 }}>
          No Records Found.
        </TableRow>
      );
    }

    return props.rows.map((row, rowIndex) => {
      return <TableRow key={rowIndex}>{showRow(row)}</TableRow>;
    });
  };

  return (
    <>
      <div style={{ overflow: "auto", maxHeight: 600 }}>
        <Table stickyHeader>
          <TableHead>
            <TableRow>{showColumns()}</TableRow>
          </TableHead>
          <TableBody>{showRows()}</TableBody>
        </Table>
        <Divider />
      </div>

      <TablePagination
        rowsPerPageOptions={[5, 10, 25]}
        component="div"
        count={props.total}
        rowsPerPage={props.rowsPerPage}
        page={props.page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </>
  );
};
export { CustomTable as Table };
