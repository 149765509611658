import React from 'react';
import Popup from 'reactjs-popup';

interface PropsInterface {
    open: boolean;
    children: any;
    handleClose: () => void;
}

export const Popover: React.FC<PropsInterface> = (props: PropsInterface) => {

    return (
        <Popup open={props.open} onClose={props.handleClose} closeOnEscape={false} closeOnDocumentClick={false}>
            {props.children}
        </Popup>
    )
}
