import React, { useCallback, useState } from 'react';
import { Box, Container, Typography, Unstable_Grid2 as Grid, colors } from '@mui/material';
import { LoginDetails } from './LoginDetails';

export const Login: React.FC<any> = () => {
    const [loginMethod, setLoginMethod] = useState('email');

    // const handleLoginMethodChange = useCallback(
    //     (event: any, value: string) => {
    //         setLoginMethod(value);
    //     },
    //     []
    // );

    return (
        <Container
            maxWidth='sm'
            sx={{
                background: colors.common.white,
            }}
        >
            <Box
                component="main"
                sx={{
                    flexGrow: 1,
                    py: 8,
                }}
            >
                <Typography sx={{ mb: 3 }} variant="h4">Login</Typography>
                {/* <Tabs
                    onChange={handleLoginMethodChange}
                    sx={{ mb: 3 }}
                    value={loginMethod}
                >
                    <Tab
                        label="Email"
                        value="email"
                    />
                    <Tab
                        label="Phone Number"
                        value="phoneNumber"
                    />
                </Tabs> */}
                <Grid xs={12} md={6} lg={4}>
                    <LoginDetails loginMethod={loginMethod} />
                </Grid>
            </Box>
        </Container>
    )
}
