import React from "react";
import {
  FormControl,
  InputLabel,
  Select,
  FormHelperText,
  MenuItem,
} from "@mui/material";

interface OptionInterface {
  label: string;
  value: string;
}
interface PropsInterface {
  field: any;
  error: string | null;
  value: number | Array<number>;
  handleChange: (event: any) => void;
}

const SelectField: React.FC<PropsInterface> = ({
  field,
  error,
  value,
  handleChange,
}: PropsInterface) => {
  const showMenu = (options: Array<OptionInterface>) =>
    options.map((option: OptionInterface) => (
      <MenuItem key={option.value} value={option.value}>
        {option.label}
      </MenuItem>
    ));

  return (
    <FormControl fullWidth>
      <InputLabel>{field.label}</InputLabel>
      <Select
        fullWidth
        name={field.key}
        onChange={handleChange}
        value={value}
        error={!!error}
        multiple={!!field.multiple}
      >
        {field.customMenu
          ? field.customMenu(field.options)
          : showMenu(field.options)}
      </Select>
      <FormHelperText error>{error ?? ""}</FormHelperText>
    </FormControl>
  );
};

export default SelectField;
