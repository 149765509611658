import React from 'react';
import { styled } from '@mui/material';
import { Routes, Route } from 'react-router-dom';
import { Login } from '../pages/login/Login';

const AuthContainer = styled('div')`
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`

export const AuthRouter = () => {
  return (
    <AuthContainer>
      <Routes>
        <Route path="/login" element={<Login />} />
      </Routes>
    </AuthContainer>
  );
};
