const TOKEN_KEY = process.env.REACT_APP_TOKEN_KEY ?? "TOKEN";

export function saveTokens(tokens: any) {
  sessionStorage.setItem(TOKEN_KEY, tokens);
}

export function getTokens() {
  const stringifiedToken = sessionStorage.getItem(TOKEN_KEY);

  if (!stringifiedToken) return;
  return stringifiedToken;
}

export function deleteTokens() {
  sessionStorage.removeItem(TOKEN_KEY);
}
export function getHeaders() {
  const token = getTokens();

  if (!token) return null;

  return {
    headers: {
      authorization: `Bearer ${token}`,
    },
  };
}
