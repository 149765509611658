
import { styled, colors } from '@mui/material';
import { Link } from 'react-router-dom';

const HeaderHeight = '4.15rem';

export const SidebarContainer = styled('div')(({ width }: { width: string | number }) => ({
  top: 0,
  width,
  padding: width ? '1rem' : 0,
  height: `calc( 100vh - ${HeaderHeight} )`,
  backgroundColor: colors.grey[900],
}));
export const commonLink = styled(Link)(() => ({
  fontSize: '1.35rem',
  display: 'grid',
  columnGap: '1rem',
  marginTop: '1rem',
  padding: '1rem 1.5rem',
  textDecoration: 'none',
  gridTemplateColumns: 'max-content max-content',
}));

export const StyledNavLink = styled(commonLink)`
  color: ${colors.grey[400]};
  &:hover {
    text-decoration: underline;
  }
`;

export const ActiveLink = styled(commonLink)`
  color: ${colors.grey[50]};
  cursor: not-allowed;
  border-radius: 12px;
  background-color: ${colors.grey[800]};
`

export const LogoContainer = styled('div')`
  font-size: 1.5rem;
  padding: 1rem 0 0.5rem 1.5rem;

  i:hover {
    transition: 0.1s,
    font-size: 2rem,
  }
  i {
    font-size: 2rem,
  }
  img {
    width:60px,
    height:60px,
  }
`;
