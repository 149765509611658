import React, { useState, useEffect } from 'react';
import { Container, colors } from '@mui/material';
import { useQuery, useMutation } from '@apollo/client';

import Form from '../../common/form/Form';
import { useToast } from '../../common/hooks/useToast';
import { CATEGORIES } from '../../graphql/queries';
import { CREATE_BUSINESS } from '../../graphql/mutation';
import { addBusinessOptions, addressFields } from './business';
import { ErrorNotifier } from '../../common/error/ErrorNotifier';

interface PropsInterface {
    handleClose: (isSuccess?: number) => void;
}

const defaultValue = {
    name: '',
    placeId: '',
    email: '',
    url: '',
    phone: '',
    category: '',
};

export const AddBusiness: React.FC<PropsInterface> = (props: PropsInterface) => {
    const { success } = useToast();

    const { data, loading } = useQuery(CATEGORIES);
    const [createBusiness, result] = useMutation(CREATE_BUSINESS);

    const [values, setValues] = useState<any>({});
    const [page, setPage] = useState(0);

    useEffect(() => {
        if (result.data) {
            success('Business created successfully');
            props.handleClose(1);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [result.data]);

    const handleAddBusiness = async (formValues: any) => {
        const { files, ...data} = formValues;
        const input: {[key: string]: any} = {};

        ['placeId', 'name', 'email', 'url', 'phone'].forEach((key: string) => {
            if (formValues.hasOwnProperty(key) && formValues[key]) {
                input[key] = data[key];
            }
        })

        if (formValues.hasOwnProperty('category') && formValues['category']) {
            input['Category'] = { connect: { id: Number(formValues['category']) }}
        }

        const value: {[key: string]: any} = { input };
        if (files) value['files'] = files;

        setValues(value);
        setPage(1)
    };

    const handleSubmit = async (formValues: any) => {
        let input = { ...values.input };

        if (formValues) {
            const { lat, lng, formattedAddress } = formValues;
            input = {
                ...input,
                BusinessAddresses: {
                    create: [{
                        lat: Number(lat),
                        lng: Number(lng),
                        formattedAddress
                    }]
                }
            };
        }

        values.input = input;
        createBusiness({ variables: values });
    };

    if (loading) return <h4>Loading...</h4>;

    const categories = data.fetchCategories.categories.map((item: { category: string; id: string; }) => ({ label: item.category, value: item.id }));

    return (
        <Container maxWidth='lg' sx={{ width: '45rem', backgroundColor: colors.common.white, p: '2rem', border: '1px solid black' }}>
            <ErrorNotifier error={result.error} />
            {page === 0 ?
                <Form
                    title="Add Business"
                    defaultValue={defaultValue}
                    submitBtnTitle="Next"
                    formFields={addBusinessOptions(categories)}
                    handleSubmit={handleAddBusiness}
                    handleCancel={() => props.handleClose()}
                /> :
                <Form
                    title="Add Business Address"
                    defaultValue={{}}
                    formFields={addressFields}
                    cancelBtnTitle="Skip"
                    handleSubmit={handleSubmit}
                    // TODO: show warning
                    handleCancel={() => handleSubmit(null)}
                />
            }
        </Container>
    );
};
