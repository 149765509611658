import { initializeApp } from "firebase/app";

export const app = initializeApp({
  apiKey: "AIzaSyDKVymrAQu-jGlfSFCaOCqgrKaIw1vwCZs",
  projectId: "safe-places-app-production",
  authDomain: "https://accounts.google.com/o/oauth2/auth",
});

// export const app = initializeApp({
//   apiKey: "AIzaSyBF-g9ZqNX8eADguPo_Mq3bYZT7OxdLgsY",
//   projectId: "safe-places-app-staging",
//   authDomain: "https://accounts.google.com/o/oauth2/auth",
// });
