import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Alert, styled } from '@mui/material';
import { ApolloError } from '@apollo/client';
import { RiArrowUpDoubleLine, RiArrowDownDoubleLine } from 'react-icons/ri';
import { useToast } from '../hooks/useToast';
import { deleteTokens } from '../manage_tokens';

const Box = styled('div')`
  border: 1px solid #bbb2b2;
  padding: 5px;
  margin: 10px 0;
  border-radius: 7px;
`
const Icon = styled('div')`
  display: flex;
  justify-content: end;
`

interface PropsInterface {
    error: ApolloError | undefined;
}

export const ErrorNotifier = (props: PropsInterface) => {
  const navigate = useNavigate();

  const { error: toastError } = useToast();

  const [error, setError] = useState('');
  const [doShowError, setDoShowError] = useState(true);

  React.useEffect(() => {
    if (props.error) {
      if (props.error.message.includes('Unique constraint failed on the fields')) {
        const message = props.error.message.split('Unique constraint failed on the fields').pop();
        setError('Unique constraint failed on the fields' + message);
  
      } else if (props.error.message === 'TokenExpiredError') {
        toastError('Session closed, please login again.');
        deleteTokens();
        setError('');
        navigate('/account/login');

      } else {
        setError(props.error.message);
      }

    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.error?.message]);

  const toggleError = () => {
    setDoShowError(showError => !showError);
  }

  if (!props.error) return null;

  return (
    <>
      <h1 role="alert">Error!</h1>
      <Box>
        <Icon>          
          {doShowError ? <RiArrowUpDoubleLine style={{ cursor: 'pointer' }} size={25} onClick={toggleError} /> : <RiArrowDownDoubleLine style={{ cursor: 'pointer' }} size={25} onClick={toggleError} />}
        </Icon>
        {doShowError ?
          <Alert severity="error">{error}</Alert> :
          null
        }
      </Box>
    </>
  );
}
