import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
// import reportWebVitals from './reportWebVitals';
import { ApolloClient, InMemoryCache, ApolloProvider } from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import { getTokens } from "./common/manage_tokens";
import { createUploadLink } from "apollo-upload-client";
import "./auth/index";
import { ThemeProvider, createTheme } from "@mui/material";
const authLink = setContext((_, { headers }) => {
  const tokenString = getTokens();

  return {
    headers: {
      ...headers,
      "Apollo-Require-Preflight": "true",
      authorization: tokenString ? `Bearer ${tokenString}` : "",
    },
  };
});

const client = new ApolloClient({
  link: authLink.concat(
    createUploadLink({ uri: process.env.REACT_APP_API_URL })
  ),
  uri: process.env.REACT_APP_API_URL,
  cache: new InMemoryCache(),
});

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

const theme = createTheme({
  components: {
    MuiTextField: {
      defaultProps: {
        variant: "standard",
      },
    },
    MuiSelect: {
      defaultProps: {
        variant: "standard",
      },
    },
    MuiInputLabel: {
      defaultProps: {
        variant: "standard",
      },
    },
  },
});

root.render(
  <React.StrictMode>
    <ApolloProvider client={client}>
      <ThemeProvider theme={theme}>
        <App />
      </ThemeProvider>
    </ApolloProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
