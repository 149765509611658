import { toast } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";

export const useToast = (commonPosition = toast.POSITION.TOP_RIGHT) => {

    const success = (msg: string, position = commonPosition) => {
        toast.success(msg, { position });
    }

    const error = (msg: string, position = commonPosition) => {
        toast.error(msg, { position });
    }

    const warn = (msg: string, position = commonPosition) => {
        toast.warn(msg, { position });
    }

    const info = (msg: string, position = commonPosition) => {
        toast.info(msg, { position });
    }

    return {
        success,
        error,
        warn,
        info,
    };
}
