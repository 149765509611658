import React, { useEffect } from "react";
import {
  Box,
  Grid,
  MenuItem,
  Typography,
  styled,
  Divider,
  FormControl,
  FormLabel,
  Switch,
  Stack,
} from "@mui/material";

import { Item } from "../../styles/common";
import Form from "../../common/form/Form";
import { useToast } from "../../common/hooks/useToast";
import { useMutation, useQuery } from "@apollo/client";
import { FETCH_USERS_WITH_TOKEN } from "../../graphql/queries";
import { ErrorNotifier } from "../../common/error/ErrorNotifier";
import { CREATE_NOTIFICATION } from "../../graphql/mutation";

const CustomMenuItem = styled(MenuItem)`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;
const Name = styled("div")`
  font-weight: 700;
  font-size: 1.25rem;
  letter-spacing: 1px;
`;
const Token = styled("div")`
  letter-spacing: 1px;
`;

interface UserInterface {
  id: number;
  firstName: string;
  lastName: string;
}
const customMenu = (options: Array<UserInterface>) => {
  return options.map((option: UserInterface) => (
    <CustomMenuItem key={option.id} value={option.id}>
      {option.firstName} {option.lastName}
    </CustomMenuItem>
  ));
};

const notificationFields = (
  options: Array<UserInterface>,
  isNotificationTypeTopic: boolean
) => {
  const addField = isNotificationTypeTopic
    ? {
        key: "topic",
        label: "Topic",
        rules: {
          required: null,
        },
        errorMessages: {
          required: "Topic is required",
        },
      }
    : {
        type: "select",
        key: "receiverIds",
        label: "Send Notification to",
        options: options,
        multiple: true,
        customMenu,
        rules: {
          required: null,
        },
        errorMessages: {
          required: "Please select atleast one reciepient",
        },
      };

  const fields = [
    {
      key: "title",
      label: "Notification title",
      rules: {
        required: null,
        minLength: 5,
      },
      errorMessages: {
        required: "Notification title is required",
        minLength:
          "Notification title length should be more than or equal to 5.",
      },
    },
    {
      key: "body",
      label: "Notification text",
      rules: {
        required: null,
        minLength: 10,
      },
      errorMessages: {
        required: "Notification text is required",
        minLength:
          "Notification text length should be more than or equal to 10.",
      },
    },
    {
      key: "image",
      label: "Image Url",
      rules: {
        required: false,
      },
      errorMessages: {},
    },
    {
      key: "screenLink",
      label: "Screen Link",
      rules: {
        required: false,
      },
      errorMessages: {},
    },
  ];

  return [addField, ...fields];
};

export const Notification = () => {
  const PER_PAGE = 50;
  const { success } = useToast();

  let variables = React.useRef<any>({
    pagination: {
      skip: 0,
      take: PER_PAGE,
    },
  });
  const { data, loading, error } = useQuery(FETCH_USERS_WITH_TOKEN, {
    variables,
  });
  const [createNotification, result] = useMutation(CREATE_NOTIFICATION, {
    errorPolicy: "all",
  });

  const [isNotificationTypeTopic, setIsNotificationTypeTopic] =
    React.useState(false);

  useEffect(() => {
    if (result.data) {
      success("Notification sent successfully.");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [result.data]);

  const handleSubmit = (values: any) => {
    let input: { [key: string]: any } = {};
    if (isNotificationTypeTopic) {
      ["title", "body", "topic", "image", "screenLink"].forEach(
        (key: string) => (input[key] = values[key])
      );
    } else {
      ["title", "body", "receiverIds", "image", "screenLink"].forEach(
        (key: string) => {
          if (key == "receiverIds") {
            input[key] = values[key].map((value: string) => parseInt(value));
          } else {
            input[key] = values[key];
          }
        }
      );
    }
    createNotification({ variables: { input } });
  };

  const handleChange = (
    _event: React.ChangeEvent<HTMLInputElement>,
    checked: boolean
  ) => {
    setIsNotificationTypeTopic(checked);
  };

  if (loading) return <h4>Loading...</h4>;

  return (
    <Box sx={{ flexGrow: 1 }}>
      <ErrorNotifier error={error ?? result.error} />
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Item>
            <Grid
              container
              direction="row"
              justifyContent="space-between"
              alignItems="center"
            >
              <Typography sx={{ m: 2 }} variant="h4">
                Notification
              </Typography>
            </Grid>
          </Item>
        </Grid>
        <Grid item xs={12}>
          <Item>
            <Grid
              container
              direction="row"
              justifyContent="center"
              alignItems="center"
            >
              <Grid item xs={12} md={8} lg={6}>
                <FormControl component="fieldset" variant="standard">
                  <FormLabel component="legend">Notification Type</FormLabel>
                  <Stack direction="row" spacing={1} alignItems="center">
                    <Typography>Token</Typography>
                    <Switch
                      checked={isNotificationTypeTopic}
                      onChange={handleChange}
                      name="Topic"
                    />
                    <Typography>Topic</Typography>
                  </Stack>
                </FormControl>
                <Form
                  title=""
                  defaultValue={{}}
                  formFields={notificationFields(
                    data ? data.fetchUsersWithTokens.users : [],
                    isNotificationTypeTopic
                  )}
                  handleSubmit={handleSubmit}
                  handleCancel={() => console.log("Cancel called")}
                />
              </Grid>
            </Grid>
          </Item>
        </Grid>
      </Grid>
    </Box>
  );
};
