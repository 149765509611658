import React from 'react';
import { ToastContainer } from 'react-toastify';

import { AppLayout } from './styles/common';
import { AppRouter } from './routers/AppRouter';

function App() {
  return (
    <AppLayout>
      <ToastContainer
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        pauseOnHover
        pauseOnFocusLoss
      />
      <AppRouter />
    </AppLayout>
  );
}

export default App;
