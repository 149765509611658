import React from 'react';
import { TextField } from '@mui/material';

interface PropsInterface {
    field: any;
    error: string | null;
    value: number;
    handleChange: (event: any) => void;
}

const NumberField: React.FC<PropsInterface> = ({ field, error, value, handleChange }: PropsInterface) => {

    return (
        <TextField
            fullWidth
            type="number"
            label={field.label}
            name={field.key}
            onChange={handleChange}
            value={value}
            inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
            error={!!error}
            helperText={error ?? ''}
        />
    )
}

export default NumberField;
