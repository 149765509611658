import React, { useState } from 'react';
import { colors, styled } from '@mui/material';
import { AiOutlinePlusCircle, AiOutlineMinusCircle } from 'react-icons/ai';

import FileField from './File';

const Block = styled('div')`
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 1rem;
`

interface PropsInterface {
    field: any;
    error: any;
    values: any;
    setValues: (value: any) => void;
}

const ArrayField: React.FC<PropsInterface> = (props: PropsInterface) => {
    const [index, setIndex] = useState(1);

    const handleChange = (event: any, i: number) => {
        props.setValues((values: any) => {
            const value = props.values && props.values[props.field.key] ? props.values[props.field.key] : [];
            const file = event.target.files[0];

            return {
                ...values,
                [props.field.key]: [
                    ...value,
                    file
                ]
            };
        });
    };

    return (
        <div>
            {new Array(index).fill(index).map((_i: number, i: number) => {
                const error = props.error && props.error[props.field.key] ? props.error[props.field.key][i] : ''
                const value = props.values && props.values[props.field.key] ? props.values[props.field.key][i] : ''

                return <Block key={i}>
                    <div>
                        <FileField field={props.field} error={error} value={value} handleChange={(event: any) => handleChange(event, i)} />
                    </div>
                    {index === i + 1 ?
                        <AiOutlinePlusCircle
                            size={30}
                            style={{ cursor: 'pointer' }}
                            title='Add field'
                            onClick={() => setIndex(index => index + 1)}
                        /> :
                        <AiOutlineMinusCircle
                            size={30}
                            color={colors.red[600]}
                            style={{ cursor: 'pointer' }}
                            title='Remove field'
                            onClick={() => {
                                const files = props.values[props.field.key];

                                if (files && files.hasOwnProperty(i)) {
                                    files.splice(i, 1);

                                    props.setValues((values: any) => {                            
                                        return {
                                            ...values,
                                            [props.field.key]: files
                                        }
                                    });
                                }
                                setIndex(index => index - 1);
                            }}
                        />
                    }
                </Block>
            })}
        </div>
    )
}

export default ArrayField;